import { GatsbyImage } from "gatsby-plugin-image";
import translate from "../../../i18n/translate";
import RenderBenefits from "../../CaseStudyUiComponents/SingleCaseStudies/RenderBenefits";
import CircleBlur from "../CircleOutlineAndBlur/CircleBlur";
import CircleOutline from "../CircleOutlineAndBlur/CircleOutline";
import * as styles from "./BenefitTabComponent.module.scss";
import cx from "classnames";
import React, { FC, useContext, useState } from "react";
import { LanguageContext } from "../../../context/languageContext";
import { UseWindowSize } from "../../hooks/UseWindowSize";
import { UseImageUrl } from "../../hooks/UseImageUrl";
import { BLOG_ACTIVE_COLOR } from "../../BlogUiComponents/BlogsMain/BlogsMain.helper";

type Props = {
  data: any;
};

const BenefitTabComponent: FC<Props> = ({ data }) => {
  const { language } = useContext(LanguageContext);
  const width = UseWindowSize();
  const [activeBenefit, setActiveBenefit] = useState(0);
  const [benefits] = useState([
    data.benefit1Title,
    data.benefit2Title,
    data.benefit3Title,
  ]);

  const [benefitsDE] = useState([
    data.benefit1TitleDE,
    data.benefit2TitleDE,
    data.benefit3TitleDE,
  ]);

  UseImageUrl(data.benefitsImage, data._rawBenefitsImage);

  return (
    <div className={styles.singleCaseChallenge}>
      <div
        className={cx(
          styles.singleCaseImageBox,
          styles.singleCaseImageBoxForth
        )}
        style={{ marginLeft: "auto" }}
      >
        <GatsbyImage
          image={data?.benefitsImage?.asset?.gatsbyImageData}
          alt={data?.altBenefitsImage || "Single Case Study Benefit"}
          className={styles.caseChallengeImage}
        />
      </div>
      <div className={styles.singleBenefitTextBox}>
        <div style={{ width: "100%" }}>
          <h2>
            .
            {language === "EN"
              ? data.benefitMainTitle
              : data.benefitMainTitleDe}
          </h2>
          {language === "EN" ? (
            <>
              <ul>
                {benefits.map((benefit, index) => (
                  <li
                    key={index}
                    onClick={() => setActiveBenefit(index)}
                    style={
                      index === activeBenefit
                        ? {
                            color: "#00ecbd",
                            width: "33%",
                            wordBreak: "break-word",
                          }
                        : { width: "33%", wordBreak: "break-word" }
                    }
                  >
                    {benefit}
                  </li>
                ))}
              </ul>
              <RenderBenefits
                language={"EN"}
                data={data}
                activeBenefit={activeBenefit}
              />
            </>
          ) : (
            <>
              <ul>
                {benefitsDE.map((benefit, index) => (
                  <li
                    key={index}
                    onClick={() => setActiveBenefit(index)}
                    style={
                      index === activeBenefit
                        ? {
                            color: BLOG_ACTIVE_COLOR,
                            width: "33%",
                            wordBreak: "break-word",
                          }
                        : { width: "33%", wordBreak: "break-word" }
                    }
                  >
                    {benefit}
                  </li>
                ))}
              </ul>
              <RenderBenefits
                language={"DE"}
                data={data}
                activeBenefit={activeBenefit}
              />
            </>
          )}
        </div>
        {/* <CircleOutline
          isBlurVisible={false}
          initialScale={0.3}
          animateScale={1}
          transitionType={"easeIn"}
          transitionDelay={0.2}
          transitionDuration={1}
          circleStyleClass={styles.contactOutlineCircleBenfits}
        /> */}
        <CircleBlur
          initialScale={0.1}
          initialOpacity={0}
          animateScale={1.5}
          animateOpacity={0.15}
          transitionType={"easeIn"}
          transitionDelay={0.2}
          transitionDuration={1}
          circleStyleClass={styles.heroBackgroundInnerCircleLeftBenfits}
        />
      </div>
    </div>
  );
};

export default BenefitTabComponent;
