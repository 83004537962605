import React, { FC } from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout/Layout";
import SingleReport from "../components/ReportsUiComponents/SingleReport/SingleReport";
import { SEO } from "../components/seo";

import { ImageSeoUrlCheck } from "../components/Shared/ImageSeoUrlCheck/ImageSeoUrlCheck";
type Props = {
  data: any;
};

const ReportTemplate: FC<Props> = ({ data }) => {
  const report = data.sanityReport;
  return (
    <Layout location="/blog">
      <SEO
        title={report.title}
        description={report.subtitle}
        type="article"
        image={ImageSeoUrlCheck(data.sanityReport._rawMainImage.asset.url)}
        author={report.author.name}
        publishedtime={report.publishedAt}
      />
      <SingleReport data={report} />
    </Layout>
  );
};

export default ReportTemplate;

export const query = graphql`
  query ($slug: String!) {
    sanityReport(id: { eq: $slug }) {
      _rawAuthor
      _rawDetailImage

      _rawBenefitsImage
      _rawTestimonialImage
      _rawMainImage(resolveReferences: { maxDepth: 20 })
      aboutImage {
        asset {
          gatsbyImageData(fit: FILLMAX)
        }
      }
      altAboutImage
      about {
        _type
        children {
          _key
          _type
          text
        }
      }
      aboutDE {
        _type
        children {
          _key
          _type
          text
        }
      }
      isNewReport
      title
      subtitle
      titleDe
      subtitleDe

      detailsDe

      publishedAt
      author {
        image {
          asset {
            url
            title
          }
        }
        alt
        position
        name
        activeMember
      }
      publishedAt

      details
      detailImage {
        asset {
          gatsbyImageData(fit: FILLMAX)
        }
      }
      altDetailImage
      mainImage {
        asset {
          gatsbyImageData(fit: FILLMAX)
        }
      }
      altMainImage
      testimonialName
      testimonialBody
      testimonialPosition
      testimonialImage {
        asset {
          gatsbyImageData(fit: FILLMAX)
        }
      }
      altTestimonialImage
      testimonialBodyDE
      testimonialNameDE
      testimonialPositionDE
      benefitsImage {
        asset {
          gatsbyImageData(fit: FILLMAX)
        }
      }
      altBenefitsImage
      benefit1 {
        _type
        children {
          text
          marks
          _type
          _key
        }
      }
      benefit2 {
        _type
        children {
          text
          marks
          _type
          _key
        }
      }
      benefit3 {
        _type
        children {
          text
          marks
          _type
          _key
        }
      }
      benefit1Title
      benefit2Title
      benefit3Title
      benefit1DE {
        _type
        children {
          _key
          _type
          marks
          text
        }
      }
      benefit2DE {
        _type
        children {
          _key
          _type
          marks
          text
        }
      }
      benefit1TitleDE
      benefit2TitleDE
      benefit3DE {
        _type
        children {
          _key
          _type
          marks
          text
        }
      }
      benefit3TitleDE
      formIntro
      submitFormMessage
      formIntroDe
      submitFormMessageDe
      benefitMainTitle
      benefitMainTitleDe
      detailsTitle
      detailsTitleDe
      formIntroduction
      formIntroductionDe
    }
  }
`;
