// extracted by mini-css-extract-plugin
export var caseTestimonialImage = "SingleReport-module--caseTestimonialImage--QGPWr";
export var contactButton = "SingleReport-module--contactButton--IOVVf";
export var detailsBoxLeft = "SingleReport-module--detailsBoxLeft--du5m1";
export var heroBackgroundInnerCircleLeft = "SingleReport-module--heroBackgroundInnerCircleLeft--Q9-Qw";
export var heroBackgroundInnerCircleOverflowText = "SingleReport-module--heroBackgroundInnerCircleOverflowText--865Dk";
export var heroWhiteBlurCirce = "SingleReport-module--heroWhiteBlurCirce--34Fts";
export var includedStudies = "SingleReport-module--includedStudies--c1Sch";
export var includedStudiesBox = "SingleReport-module--includedStudiesBox--94L2W";
export var includedStudiesBoxBottom = "SingleReport-module--includedStudiesBoxBottom--Aj6L3";
export var includedStudiesImage = "SingleReport-module--includedStudiesImage--tyI1l";
export var includedStudiesInner = "SingleReport-module--includedStudiesInner--qVmDG";
export var keyTopicsImage = "SingleReport-module--keyTopicsImage--wlraK";
export var reportBtn = "SingleReport-module--reportBtn--eRlcv";
export var reportBtnGerman = "SingleReport-module--reportBtnGerman--I93Vb";
export var reportDetailsBoxRight = "SingleReport-module--reportDetailsBoxRight--P6Iw7";
export var reportKeyTopics = "SingleReport-module--reportKeyTopics--C2vxy";
export var reportKeyTopicsRow = "SingleReport-module--reportKeyTopicsRow--oVrRW";
export var reportTopNavigation = "SingleReport-module--reportTopNavigation--jguGx";
export var reportTopicsItemBox = "SingleReport-module--reportTopicsItemBox--4WZhG";
export var singleCaseTestimonial = "SingleReport-module--singleCaseTestimonial--e7HIs";
export var singleCaseTestimonialInner = "SingleReport-module--singleCaseTestimonialInner--0Dpdp";
export var singleCaseTextBox = "SingleReport-module--singleCaseTextBox--QT5Y8";
export var singleCaseTextBoxLast = "SingleReport-module--singleCaseTextBoxLast--bTlU0";
export var singleReport = "SingleReport-module--singleReport--sw-RG";
export var singleReportDetails = "SingleReport-module--singleReportDetails--LQnlK";
export var testimonialBody = "SingleReport-module--testimonialBody--wkydo";
export var testimonialContent = "SingleReport-module--testimonialContent--Q+Mr1";
export var testimonialName = "SingleReport-module--testimonialName--PyK8v";
export var topicsColumbLeft = "SingleReport-module--topicsColumbLeft--OEGIJ";
export var topicsColumbRight = "SingleReport-module--topicsColumbRight--xcLB2";
export var topicsItem = "SingleReport-module--topicsItem--nI0e1";