import React, { FC, useContext } from "react";
import { UseWindowSize } from "../../hooks/UseWindowSize";
import * as styles from "./SingleReport.module.scss";
import { GatsbyImage } from "gatsby-plugin-image";
import arrowRightWhite from "../../../images/arrowRightWhite.svg";
import CircleBlur from "../../Shared/CircleOutlineAndBlur/CircleBlur";

import { pathWithoutSpace } from "../../ReportsUiComponents/ReportsMainHelpers";
import { UseImageUrl } from "../../hooks/UseImageUrl";
import HeroBanner from "../../Shared/HeroBanner/HeroBanner";
import HeroBannerMobile from "../../Shared/HeroBannerMobile/HeroBannerMobile";
import InnerWrapper from "../../Shared/InnerWrapper/InnerWrapper";
import { LanguageContext } from "../../../context/languageContext";
import translate from "../../../i18n/translate";
import BenefitTabComponent from "../../Shared/BenefitTabComponent/BenefitTabComponent";

type Props = {
  data: any;
};

const SingleReport: FC<Props> = ({ data }) => {
  const width = UseWindowSize();
  const registerPath = pathWithoutSpace(data.title);
  const { language } = useContext(LanguageContext);

  UseImageUrl(data.detailImage, data._rawDetailImage);
  UseImageUrl(data.mainImage, data._rawMainImage);
  UseImageUrl(data.testimonialImage, data._rawTestimonialImage);

  return (
    <div className={styles.singleReport} id="top">
      {width.width < 1200 ? (
        <HeroBannerMobile
          isNewReport={data.isNewReport}
          heading={
            data.isNewReport !== "Yes"
              ? language === "EN"
                ? data.title
                : data.titleDe
              : language === "EN"
              ? data.formIntroduction
              : data.formIntroductionDe
          }
          description={language === "EN" ? data.subtitle : data.subtitleDe}
          homeBackground={
            data.mainImage.asset.gatsbyImageData.images.fallback.src
          }
          buttonText={
            data.isNewReport !== "Yes" ? translate("download-now-report") : null
          }
          date=""
          link={`/reports/register/${registerPath}`}
          alt="Report page"
          isReport={true}
          formIntro={language === "EN" ? data.formIntro : data.formIntroDe}
          submitFormMessage={
            language === "EN" ? data.submitFormMessage : data.submitFormMessage
          }
        />
      ) : (
        <InnerWrapper>
          <HeroBanner
            isNewReport={data.isNewReport}
            heading={
              data.isNewReport !== "Yes"
                ? language === "EN"
                  ? data.title
                  : data.titleDe
                : language === "EN"
                ? data.formIntroduction
                : data.formIntroductionDe
            }
            description={language === "EN" ? data.subtitle : data.subtitleDe}
            homeBackground={
              data.mainImage.asset.gatsbyImageData.images.fallback.src
            }
            buttonText={
              data.isNewReport !== "Yes"
                ? translate("download-now-report")
                : null
            }
            date=""
            link={`/reports/register/${registerPath}`}
            isImageCentered={true}
            isReport={true}
            formIntro={language === "EN" ? data.formIntro : data.formIntroDe}
            submitFormMessage={
              language === "EN"
                ? data.submitFormMessage
                : data.submitFormMessage
            }
          />
        </InnerWrapper>
      )}
      <InnerWrapper>
        {data.details && (
          <div
            className={styles.singleReportDetails}
            style={
              data.includedStudies1Text === null
                ? { flexDirection: "row-reverse" }
                : null
            }
          >
            <div className={styles.detailsBoxLeft}>
              <GatsbyImage
                image={data.detailImage.asset.gatsbyImageData}
                className={styles.includedStudiesImage}
                alt={data?.altDetailImage || "Single Report Detail Image"}
              />
            </div>
            <div className={styles.reportDetailsBoxRight}>
              <div>
                <h2>
                  .{language === "EN" ? data.detailsTitle : data.detailsTitleDe}
                </h2>
                <p>{language === "EN" ? data.details : data.detailsDe}</p>
              </div>
            </div>
          </div>
        )}

        <div style={{ width: "100%", marginTop: "200px" }}>
          <BenefitTabComponent data={data} />
        </div>

        {data.testimonialName || data.testimonialNameDE ? (
          <div className={styles.singleCaseTestimonial}>
            <div className={styles.singleCaseTestimonialInner}>
              <GatsbyImage
                image={data?.testimonialImage?.asset?.gatsbyImageData}
                alt={
                  data?.altTestimonialImage ||
                  "Single Case Study Testimonial Image"
                }
                className={styles.caseTestimonialImage}
              />
              <div className={styles.testimonialContent}>
                <h4>
                  {language === "EN"
                    ? data.testimonialName
                    : data.testimonialNameDE}
                </h4>
                <p className={styles.testimonialName}>
                  {language === "EN"
                    ? data.testimonialPosition
                    : data.testimonialPositionDE}
                </p>
                <p className={styles.testimonialBody}>
                  {language === "EN"
                    ? data.testimonialBody
                    : data.testimonialBodyDE}
                </p>

                <CircleBlur
                  initialScale={0.1}
                  initialOpacity={0}
                  animateScale={1.5}
                  animateOpacity={0.15}
                  transitionType={"easeIn"}
                  transitionDelay={0.2}
                  transitionDuration={1}
                  circleStyleClass={styles.heroBackgroundInnerCircleLeft}
                />
              </div>
            </div>
          </div>
        ) : null}

        {data.isNewReport === "Yes" && (
          <div
            className={styles.reportTopNavigation}
            style={{ marginTop: "200px" }}
          >
            <h2>{translate("register-to-download-report")}</h2>

            <a
              href="#top"
              style={{
                zIndex: 9999999999999999,
                display: "flex",
                justifyContent: "space-around",
                marginTop: "0px",
                width: "250px !important",
                fontFamily: "Poppins",
              }}
              className={`${styles.contactButton}`}
            >
              <span>{translate("report-download-btn")}</span>
              <img src={arrowRightWhite} alt={" Get Report Now"} />
            </a>
          </div>
        )}
      </InnerWrapper>
    </div>
  );
};

export default SingleReport;
